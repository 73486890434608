@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.navigationContainer {
  display: flex;
  align-items: center;
  color: $primary-main;
  padding: 1.25rem 1.125rem;
  right: 0;
  z-index: 8;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(0.188rem);
  justify-content: space-between;
  box-shadow: rgba(34, 51, 84, 0.2) 0 0.125rem 0.5rem -0.188rem, rgba(34, 51, 84, 0.1) 0 0.313rem 1.375rem -0.25rem;
  width: 100%;

  @include bp (m) {
    height: 5rem;
    left: 18.125rem;
    padding: 0 1.125rem;
    position: fixed;
    width: calc(100% - 18.125rem);
  }

  .iconItems {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    button {
      color: $primary-main;
    }
  }

  .navigationItems {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding: 0.063rem 0;
    flex-direction: column;
    gap: 0.5rem;

    @include bp (m) {
      padding: 0.063rem 0 0.063rem 2.625rem;
    }

    @include bp (s) {
      flex-direction: row;
    }

    .navigationItem {
      display: flex;
      color: $color-grey;
      justify-content: flex-start;
      padding: 0.875rem 1.688rem;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      outline: 0;
      border: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      font-family: $globalFont;
      font-size: 0.875rem;
      line-height: 1.75;
      min-width: 4rem;
      border-radius: 0.625rem;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-weight: 500;
      text-transform: none;
      margin: 0 0 0.25rem;

      &:hover {
        background-color: rgba(25, 118, 210, 0.06);
      }
    }
  }
}
