@import '../../../styles/variables';
@import '../../../styles/mixins';

.authLayout {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .backdrop {
    width: 100%;
    height: 100%;
    background-color: $auth-backdrop-background-color;
    backdrop-filter: blur(0.188rem);

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 100vh;

      .logo {
        padding-top: 0.625rem;
        padding-left: 2rem;

        > img {
          width: 9rem;
        }
      }

      .ababaLogo {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: auto;
        padding-bottom: 2rem;

        > img {
          width: 9rem;
        }
      }

      .contentInner {
        margin: 0rem 1rem;

        @include bp(xs) {
          width: 24.125rem;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 1rem;
        }

        .innerCard {
          padding: 2rem 1rem;
          background-color: $bright-color;
          border-radius: 0.75rem;
          margin-top: 1rem;
          border: 0.0625rem solid $card-border;
          box-shadow: 0 0 1.25rem 0 $very-light-black-color;
          overflow: hidden;

          @include bp(xs) {
            padding: 2rem 2.25rem;
          }
        }
      }
    }
  }
}
