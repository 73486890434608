$poppinsFont: "Poppins", sans-serif;
$robotoFont: "Roboto", sans-serif;
$globalFont: $robotoFont;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-light: #2d5452;
$primary-main: #223D3C;
$primary-dark: #1c3130;
$primary-extra-dark: #1E2423;

$primary-button-background: #2d5452;
$primary-button-background-hover: #223D3C;
$primary-button-shadow: rgba(34, 61, 60, 0.24) 0 0.5rem 1rem 0;

$color-grey: rgb(126, 136, 143);
$color-grey-light: rgb(218, 222, 227);
$color-grey-text: #D9D9D9;
$color-grey-icon: #131A29;
$color-grey-border: #EDEDED;
$color-orange: #E9471D;
$color-orange-darker: #ee4216;
$color-orange-darkest: #c7350f;

$label: rgb(145, 158, 171);
$label-focused: $primary-light;
$label-error: #d32f2f;

$input-border: rgba(145, 158, 171, 0.32);
$input-border-focused: $primary-light;
$input-border-error: $label-error;
$input-error-text: $label-error;

$background-color: #E8E9EB;
$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #FFFFFF;
$black-color: #000000;

$red-color: #E13119;
$red-color-darker: #e04321;
$red-color-brighter: #EFC5BD;

$green: rgb(34, 154, 22);
$light-green: #B6D0AD;
$very-light-green: #EEF6DF;

$warning-color: #F19A3E;
$warning-color-brighter: #F6C48D;
$warning-color-darker: #98540B;

$sidebar-color: #313638;
$navigation-color: #313638;

$input-color: #919eab;
$input-border-color: rgba(145, 158, 171, 0.32);
$input-hover-color: rgba(0, 0, 0, 0.87);
$input-focus-color: #2d5452;

$secondary-color: #1c5158;
$card-title: #8d8d8d;
$card-border: #e8e8e8;
$social-login-button: #9bd2ce33;
$social-login-button-hover: #75979533;
$auth-backdrop-background-color: rgba(1, 11, 11, 0.35);
$very-light-black-color: rgba(0, 0, 0, 0.05);
$icon-button-background: rgba(155, 210, 206, 0.3);
$benefit-title: #161634;
$purchase-border-color: rgba(145, 158, 171, 0.2);
$date-border: #bbc5d5;
$secondary-button-background-hover: rgba(155, 210, 206, 0.2);

$card-hover-color: rgba(155, 210, 206, 0.2);
$card-background-color: rgba(1, 11, 11, 0.34);
$card-text-active: #f4f5f6;
$card-title-text: #11141a;
$hover-primary-color: #9bd2ce;
$drawer-border-color: #e6e6e6;
$total-pay: #929292;
$calendar-shadow: #919eab;

$child-select-background: #F4F6F8;