@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.popover {
  :global(.MuiPaper-root) {
    background: transparent;
    border-radius: 0.5rem;
  }
}

.popoverContent {
  background-color: $bright-color;
  color: $black-color;
  background-image: none;
  overflow: hidden auto;
  min-height: 1rem;
  max-height: calc(100% - 2rem);
  outline: 0;
  max-width: 100%;
  opacity: 1;
  transform: none;
  transition: opacity 230ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 153ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  padding: 1rem;

  .freeAttendanceCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.688rem;
    background-color: $icon-button-background;
    padding: 0.875rem 0.813rem;
    border-radius: 0.5rem;

    .freeAttendanceIcon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .freeAttendanceText {
      display: flex;
      flex-direction: column;
      gap: 0.313rem;

      .freeAttendanceTitle {
        font-family: $globalFont;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: $benefit-title;
      }

      .freeAttendanceSubTitle {
        font-family: $globalFont;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        color: $secondary-color;
      }
    }
  }
}