@import "../../styles/mixins";
@import "../../styles/variables";

.modalContainer {
  .modalTitle {
    display: flex;
    justify-content: space-between;
  }

  :global(.MuiDialog-paperScrollPaper) {
    border-radius: 1rem;
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    margin: 0;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 4rem);
    max-width: 75rem;
    width: 100%;
    box-shadow: rgba(145, 158, 171, 0.24) 0 0 0.25rem 0, rgba(145, 158, 171, 0.24) 0 1.5rem 40.5rem 0;

    @include bp(s) {
      min-width: 37.5rem;
      margin: 2rem;
    }

    h2 {
      margin: 0;
      font-weight: 700;
      line-height: 1.55556;
      font-family: $globalFont;
      flex: 0 0 auto;
      padding: 1.5rem 1.5rem 0;
      font-size: 1.0625rem;

      @include bp(s) {
        font-size: 1.125rem;
      }
    }

    :global(.MuiDialogContent-root) {
      padding: 0.375rem 1.5rem 1.5rem;
      flex: 1 1 auto;
      overflow-y: auto;
      border-top: 0;
      border-bottom: 0;

      :global(.MuiDialogContentText-root) {
        margin: 0;
        line-height: 1.5;
        font-size: 0.875rem;
        font-family: $globalFont;
        font-weight: 400;
        color: rgb(99, 115, 129);
      }
    }

    :global(.MuiDialogActions-root) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 0 0 auto;
      padding: 1.5rem;
    }
  }

  &.isSmall {
    :global(.MuiDialog-paperScrollPaper) {
      max-width: 37.5rem;
    }
  }
}
